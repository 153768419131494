<template>
    <div v-if="$ability.can('read', this.$route.meta.resource)">
        <add-product :store-module-name="this.storeModuleName" :is-modal-active.sync="isModalActive" :show.sync="show"
            :data.sync="modalData" />
        <b-overlay :show="show" rounded="sm">
            <b-card no-body class="mb-0">
                <div class="m-2">
                    <b-row>
                        <b-col cols="6">
                            <label>{{ $t('Amount') }}</label>
                            <cleave id="amount" :value="amount" @input="typingAmount($event)" class="form-control"
                                :raw="false" :options="optionCleave.number" :placeholder="$t('Amount')" />
                        </b-col>
                        <b-col cols="6">
                            <label>{{ $t('Calculation Method') }}</label>
                            <v-select id="calculationMethod" v-model="calculationMethod"
                                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :options="OptionCal"
                                :clearable="false" :reduce="(OptionCal) => OptionCal.value" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <label>{{ $t('Show') }}</label>
                            <label class="text-muted ml-2 mr-2">{{ currentPage * perPage - (perPage - 1) }} -
                                {{
                                    queriedItems - currentPage * perPage > 0
                                    ? currentPage * perPage
                                    : queriedItems
                                }}
                                {{ $t("of") }} {{ queriedItems }}</label>
                            <label for="">{{ $t('entries') }}</label>
                            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                :options="perPageOptions" :clearable="false" @input="pageSize" />

                        </b-col>

                        <b-col cols="12" md="6">
                            <label>{{ $t('Search') }}</label>
                            <div class="d-flex align-items-center justify-content-end">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                    :placeholder="$t('Search') + '...'" @input="typing($event)" />
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="d-flex"
                                    @click="sidebarAddNew()">
                                    <feather-icon icon="PlusIcon" />
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </div>
                <b-table ref="refUserListTable" striped responsive class="position-relative" :items="respData"
          style="height: 60vh;"

                    :fields="tableColumns" primary-key="_id" show-empty :empty-text="$t('No matching records found')">
                    <template #cell(images)="data">
                        <b-row>
                            <b-img v-if="data.item.images.length > 0" :src="data.item.images[0]" fluid thumbnail
                                width="100px" :alt="'Users_' + data.item._id" />
                            <b-img v-else :src="require('@/assets/images/blank/no_image.png')" fluid thumbnail width="100px"
                                :alt="'Users_' + data.item._id" />
                        </b-row>
                    </template>
                    <template #cell(name)="data">
                        <p>{{ data.item.optionNameTranslate === 'multiple' ?
                            showFromCurrentLanguage(data.item.nameTranslate) : data.item.name }}</p>
                    </template>
                    <!-- Column: Images -->
                    <template #cell(productCategory)="data">
                        <b-row>
                            <p v-for="(itemCategory, index) in data.item.productCategory" :key="index"
                                class="align-text-bottom line-height-1">{{ showFromCurrentLanguage(itemCategory['name']) }}
                                , </p>
                        </b-row>
                    </template>
                    <template #cell(bonus)="data">
                        <feather-icon color="green" v-if="data.item.bonus === true" icon="CheckCircleIcon" size="18" />
                        <feather-icon color="red" v-if="data.item.bonus === false" icon="XCircleIcon" size="18" />
                    </template>
                    <!-- Column: Images -->
                    <template #cell(updated)="data">
                        <b-row>
                            <p>{{ data.item.updated | formatDateTime }}</p>
                        </b-row>
                    </template>
                    <!-- Column: Images -->
                    <template #cell(created)="data">
                        <b-row>
                            <p>{{ data.item.created | formatDateTime }}</p>
                        </b-row>
                    </template>
                    <!-- Column: Action -->
                    <template #cell(action)="data">
                        <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
                            <template #button-content>
                                <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                            </template>

                            <b-dropdown-item @click="deleteData(data.item._id)">
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50">{{ $t('Delete') }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-table>
                <div class="mx-2 mb-2">
                    <b-row>
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-start">
                            <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                                {{
                                    queriedItems - currentPage * perPage > 0
                                    ? currentPage * perPage
                                    : queriedItems
                                }}
                                {{ $t("of") }} {{ queriedItems }}</span>
                        </b-col>
                        <!-- Pagination -->
                        <b-col cols="12" sm="6"
                            class="d-flex align-items-center justify-content-center justify-content-sm-end">

                            <b-pagination v-model="currentPage" :total-rows="queriedItems" :per-page="perPage" first-number
                                last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item"
                                @input="nextPage">
                                <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                </template>
                                <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                </template>
                            </b-pagination>

                        </b-col>

                    </b-row>
                </div>
            </b-card>
        </b-overlay>
    </div>
</template>
    
<script>
import {
    BCard, BRow, BCol,
    BImg,
    BAvatar,
    BFormInput, BButton,
    BTable, BPagination, BDropdown, BOverlay,
    BCardText,
    BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import debounce from 'debounce'
import Cleave from 'vue-cleave-component'
import AddProduct from './Modal/AddProduct.vue'


export default {
    components: {
        BCard,
        BRow,
        BCol,
        // eslint-disable-next-line vue/no-unused-components
        BImg,
        // eslint-disable-next-line vue/no-unused-components
        BAvatar,
        BFormInput,
        BButton,
        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BCardText,

        // BFormFile,
        // BBadge,
        // BLink,
        // BMedia,
        // BAvatar,
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
        Cleave,
        AddProduct,
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            avatarText,
            dataId: null,
            currentPage: 1,
            searchQuery: '',
            perPage: localStorage.getItem('itemsPerPage') ? localStorage.getItem('itemsPerPage') : 30,
            isModalActive: false,
            modalData: {},
            show: false,
            amount: "0",
            calculationMethod: 'constant',
            optionCleave: {
                number: {
                    numeral: true,
                },
            },
            perPageOptions,
        }
    },
    props: {
        storeModuleName: {
            type: String,
            required: true,
        },
        typeTime: {
            type: String,
            required: true,
        },
        currentDate: {
            required: true,
        },
    },
    watch: {
        calculationMethod(val, oldVal) {
            if (val !== oldVal) {
                if (this.dataId) {
                    const obj = {
                        id: this.dataId,
                        amount: this.amount.replaceAll(',', ''),
                        calculationMethod: val,
                    }
                    this.show = true
                    store
                        .dispatch(`${this.storeModuleName}/update`, obj)
                        // eslint-disable-next-line no-unused-vars
                        .then(result => {
                            this.show = false
                            // 
                            // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                            // eslint-disable-next-line no-unused-vars
                        }).catch(error => {
                            this.show = false
                            console.log(error)
                            this.$toast({
                                component: ToastificationContent,
                                position: 'bottom-right',
                                props: {
                                    title: this.$t('Error'),
                                    icon: 'ErrorIcon',
                                    variant: 'danger',
                                    text: this.$t(error.response.data.message),
                                },
                            })
                            // console.log('fetchUsers Error : ', error)
                            // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                        })
                }

            }
        },
        typeTime(val, oldVal) {
            if (val !== oldVal) {
                const obj = {
                    typeTime: val,
                    selectedDate: this.currentDate,
                    currentPage: this.currentPage,
                    pageSize: this.perPage,
                    searchQuery: this.searchQuery,
                }
                console.log(val)

                this.show = true
                store
                    .dispatch(`${this.storeModuleName}/get`, obj)
                    // eslint-disable-next-line no-unused-vars
                    .then(result => {
                        this.show = false
                        let flashSaleData = result.data.flashSaleData
                        this.dataId = flashSaleData._id
                        this.amount = flashSaleData.amount.toString()
                        this.calculationMethod = flashSaleData.calculationMethod
                        this.modalData = { _id: flashSaleData._id }
                        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                        // eslint-disable-next-line no-unused-vars
                    }).catch(error => {
                        this.show = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'bottom-right',
                            props: {
                                title: this.$t('Error'),
                                icon: 'ErrorIcon',
                                variant: 'danger',
                                text: this.$t(error.response.data.message),
                            },
                        })
                        // console.log('fetchUsers Error : ', error)
                        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                    })
            }
        },
        currentDate(val, oldVal) {
            if (val !== oldVal) {
                const obj = {
                    typeTime: this.typeTime,
                    selectedDate: val,
                    currentPage: this.currentPage,
                    pageSize: this.perPage,
                    searchQuery: this.searchQuery,
                }
                console.log(val)

                this.show = true
                store
                    .dispatch(`${this.storeModuleName}/get`, obj)
                    // eslint-disable-next-line no-unused-vars
                    .then(result => {
                        this.show = false
                        let flashSaleData = result.data.flashSaleData
                        this.dataId = flashSaleData._id
                        this.amount = flashSaleData.amount.toString()
                        this.calculationMethod = flashSaleData.calculationMethod
                        this.modalData = { _id: flashSaleData._id }

                        // 
                        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                        // eslint-disable-next-line no-unused-vars
                    }).catch(error => {
                        this.show = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'bottom-right',
                            props: {
                                title: this.$t('Error'),
                                icon: 'ErrorIcon',
                                variant: 'danger',
                                text: this.$t(error.response.data.message),
                            },
                        })
                        // console.log('fetchUsers Error : ', error)
                        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                    })
            }


        },
    },
    computed: {
        tableColumns() {
            return [
                { key: 'images', label: this.$t('Image'), sortable: false },
                { key: 'name', label: this.$t('Name'), sortable: false },
                { key: 'limit', label: this.$t('Limit'), sortable: false },
                { key: 'productCategory', label: this.$t('Product Category'), sortable: false },
                { key: 'action', label: this.$t('ACTION'), sortable: false },
            ]
        },
        respData() {
            return store.state[this.storeModuleName].respData != null ? store.state[this.storeModuleName].respData.data : []
        },
        queriedItems() {
            return store.state[this.storeModuleName].respData != null ? store.state[this.storeModuleName].respData.max : 0
        },
        OptionCal() {
            return [
                { name: this.$t('Percent'), value: 'percent' },
                { name: this.$t('Constant'), value: 'constant' },
            ]
        },
    },
    created() {
        // Register module
        if (!store.hasModule(this.storeModuleName)) store.registerModule(this.storeModuleName, storeModule)
        const obj = {
            typeTime: this.typeTime,
            selectedDate: this.currentDate,
            currentPage: this.currentPage,
            pageSize: this.perPage,
            searchQuery: this.searchQuery,
        }

        this.show = true
        store
            .dispatch(`${this.storeModuleName}/get`, obj)
            // eslint-disable-next-line no-unused-vars
            .then(result => {
                this.show = false
                let flashSaleData = result.data.flashSaleData
                this.dataId = flashSaleData._id
                this.amount = flashSaleData.amount.toString()
                this.calculationMethod = flashSaleData.calculationMethod
                this.modalData = { _id: flashSaleData._id }

                // 
                // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                // eslint-disable-next-line no-unused-vars
            }).catch(error => {
                this.show = false
                this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                        title: this.$t('Error'),
                        icon: 'ErrorIcon',
                        variant: 'danger',
                        text: this.$t(error.response.data.message),
                    },
                })
                // console.log('fetchUsers Error : ', error)
                // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
            })
        this.typingAmount = debounce(this.typingAmount, 500)
        this.typing = debounce(this.typing, 500)
    },
    methods: {
        sidebarAddNew(){
            this.isModalActive = true
        },
        showFromCurrentLanguage(data) {
            if (data) {
                const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
                if (indexLang > -1) {
                    return data[indexLang].value
                }
                return ''
            }
            return ''
        },
        typingAmount(value) {
            if (this.dataId) {
                const obj = {
                    id: this.dataId,
                    amount: value.replaceAll(',', ''),
                    calculationMethod: this.calculationMethod,
                }
                this.show = true
                store
                    .dispatch(`${this.storeModuleName}/update`, obj)
                    // eslint-disable-next-line no-unused-vars
                    .then(result => {
                        this.show = false
                        // 
                        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                        // eslint-disable-next-line no-unused-vars
                    }).catch(error => {
                        this.show = false
                        this.$toast({
                            component: ToastificationContent,
                            position: 'bottom-right',
                            props: {
                                title: this.$t('Error'),
                                icon: 'ErrorIcon',
                                variant: 'danger',
                                text: this.$t(error.response.data.message),
                            },
                        })
                        // console.log('fetchUsers Error : ', error)
                        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                    })
            }

        },
        typing(value) {
            console.log(value)
            this.searchQuery = value
            const obj = {
                typeTime: this.typeTime,
                selectedDate: this.currentDate,
                currentPage: this.currentPage,
                pageSize: this.perPage,
                searchQuery: this.searchQuery,
            }
            this.show = true
            store
                .dispatch(`${this.storeModuleName}/get`, obj)
                .then(result => {
                    this.show = false
                    let flashSaleData = result.data.flashSaleData
                    this.dataId = flashSaleData._id
                    this.amount = flashSaleData.amount.toString()
                    this.calculationMethod = flashSaleData.calculationMethod
                    this.modalData = { _id: flashSaleData._id }

                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                }).catch(error => {
                    this.show = false
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
        deleteData(id) {
            this.$bvModal
                .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
                    title: this.$t('Please Confirm'),
                    cancelVariant: 'outline-secondary',
                    okVariant: 'danger',
                    okTitle: this.$t('Yes'),
                    cancelTitle: this.$t('No'),
                    centered: true,
                })
                .then(value => {
                    if (value === true) {
                        this.show = true
                        store
                            .dispatch(`${this.storeModuleName}/delete`, { id: this.dataId, productId: id, })
                            .then(result => {
                                this.show = false

                            }).catch(error => {
                                this.show = false
                                console.log('fetchUsers Error : ', error)
                                this.$toast({
                                    component: ToastificationContent,
                                    position: 'bottom-right',
                                    props: {
                                        title: this.$t('Error'),
                                        icon: 'ErrorIcon',
                                        variant: 'danger',
                                        text: this.$t(error.response.data.message),
                                    },
                                })
                            })
                    }
                    //  else {
                    //   this.showToast(
                    //     'warning',
                    //     'top-right',
                    //     `${this.$t('DELETE_CANCEL')}!`,
                    //     'AlertCircleIcon',
                    //     this.$t('DELETE_CANCEL'),
                    //   )
                    // }
                })
        },
        pageSize(size) {
            localStorage.setItem('itemsPerPage', size)
            this.perPage = size
            const obj = {
                typeTime: this.typeTime,
                selectedDate: this.currentDate,
                currentPage: this.currentPage,
                pageSize: this.perPage,
                searchQuery: this.searchQuery,
            }
            this.show = true
            store
                .dispatch(`${this.storeModuleName}/get`, obj)
                .then(result => {
                    this.show = false
                    let flashSaleData = result.data.flashSaleData
                    this.dataId = flashSaleData._id
                    this.amount = flashSaleData.amount.toString()
                    this.calculationMethod = flashSaleData.calculationMethod
                    this.modalData = { _id: flashSaleData._id }

                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                }).catch(error => {
                    this.show = false
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
        nextPage(page) {
            this.currentPage = page
            const obj = {
                typeTime: this.typeTime,
                selectedDate: this.currentDate,
                currentPage: this.currentPage,
                pageSize: this.perPage,
                searchQuery: this.searchQuery,
            }
            this.show = true
            store
                .dispatch(`${this.storeModuleName}/get`, obj)
                .then(result => {
                    this.show = false
                    let flashSaleData = result.data.flashSaleData
                    this.dataId = flashSaleData._id
                    this.amount = flashSaleData.amount.toString()
                    this.calculationMethod = flashSaleData.calculationMethod
                    this.modalData = { _id: flashSaleData._id }

                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                }).catch(error => {
                    this.show = false
                    console.log('fetchUsers Error : ', error)
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
        showToast(variant, position, text, icon, title) {
            this.$toast(
                {
                    component: ToastificationContent,
                    props: {
                        title,
                        icon,
                        text,
                        // icon: 'InfoIcon',
                        // text: 'I do not think that word means what you think it means.',
                        variant,
                    },
                },
                {
                    position,
                },
            )
        },
    },
}
</script>
    
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
    display: inline-block;
    width: 50;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.b-col-custom {
    margin-top: 0.5rem;
}
</style>
    