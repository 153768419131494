<template>
    <b-modal :visible="isModalActive" :title="$t('Flash Sale')" ok-title="Accept" size="lg"
        @change="(val) => $emit('update:is-modal-active', val)">
        <div class="m-2">

            <v-select id="productId" v-model="productId" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name"
                :options="products" :clearable="false" :reduce="(products) => products.id" />
        </div>

        <template #modal-footer>
            <div class="w-100">

                <div class="float-right">

                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" size="sm" class="mr-2"
                        @click="submit()">
                        {{ $t('Confirm') }}
                        <feather-icon icon="ShoppingBagIcon" size="12" />
                    </b-button>
                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" size="sm" class="mr-2"
                        @click="$emit('update:is-modal-active', false)">
                        {{ $t('Cancel') }}
                        <feather-icon icon="ShoppingBagIcon" size="12" />
                    </b-button>

                </div>

            </div>
        </template>
    </b-modal>
</template>
  
<script>

import {
    BModal,
    BRow,
    BCol,
    BTabs,
    BTab,
    BCardText,
    BFormCheckbox,
    BButton,
    BFormInput,
    BFormGroup,
    BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'


export default {
    components: {
        // eslint-disable-next-line vue/no-unused-components
        vSelect,
        BModal,
        // eslint-disable-next-line vue/no-unused-components
        BRow,
        // eslint-disable-next-line vue/no-unused-components
        BCol,
        BTabs,
        BTab,
        // eslint-disable-next-line vue/no-unused-components
        BCardText,
        // eslint-disable-next-line vue/no-unused-components
        BFormCheckbox,
        // eslint-disable-next-line vue/no-unused-components
        BButton,
        BFormInput,
        // eslint-disable-next-line vue/no-unused-components
        BFormGroup,
        BOverlay,
        // eslint-disable-next-line vue/no-unused-components
    },
    directives: {
        Ripple,
    },
    mixins: [togglePasswordVisibility],
    model: {
        prop: 'isModalActive',
        event: 'update:is-modal-active',
    },
    props: {
        storeModuleName: {
            type: String,
            required: true,
        },
        isModalActive: {
            type: Boolean,
            required: true,
        },
        data: {
            type: Object,
            default: () => { },
        },
        show: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            dataId: null,
            productId: '',
            products: [],
            required,
        }
    },
    watch: {
        isModalActive(val) {
            if (val) {
                if (Object.entries(this.data).length === 0) {
                    // this.$refs.formData.reset()
                    this.initValues()
                } else {
                    const {
                        _id,
                    } = this.data
                    this.dataId = _id
                    this.getProducts()
                }
            }
        },
    },
    methods: {
        initValues() {
            this.dataId = null
            this.productId = ''
            this.products = []
        },
        showFromCurrentLanguage(data) {
            const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
            if (indexLang > -1) {
                return data[indexLang].value
            }
            return ''
        },
        submit() {
            store
                .dispatch(`${this.storeModuleName}/add`, { id: this.dataId, productId: this.productId, })
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                    this.$emit('update:is-modal-active', false)
                    // 
                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                    // eslint-disable-next-line no-unused-vars
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // console.log('fetchUsers Error : ', error)
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
        getProducts() {
            store
                .dispatch(`${this.storeModuleName}/getProducts`, { id: this.dataId })
                // eslint-disable-next-line no-unused-vars
                .then(result => {
                    let data = [];
                    for (let index = 0; index < result.data.data.length; index++) {
                        let product = result.data.data[index]
                        if (product.optionNameTranslate === "single") {
                            const productData = {
                                id: product._id,
                                name: product.name,
                            }
                            data.push(productData)
                        }
                        else if (product.optionNameTranslate === "multiple") {
                            const productData = {
                                id: product._id,
                                name: this.showFromCurrentLanguage(product.nameTranslate),
                            }
                            data.push(productData)
                        }
                    }
                    this.products = data
                    // 
                    // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
                    // eslint-disable-next-line no-unused-vars
                }).catch(error => {
                    this.$toast({
                        component: ToastificationContent,
                        position: 'bottom-right',
                        props: {
                            title: this.$t('Error'),
                            icon: 'ErrorIcon',
                            variant: 'danger',
                            text: this.$t(error.response.data.message),
                        },
                    })
                    // console.log('fetchUsers Error : ', error)
                    // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
                })
        },
    },
}
</script>
  
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
  